<template>
  <div>
    <router-link class="tpos" :to="router">
      <div class="l-block">
        <b class="ileft" style="font-size:18px">{{ title }}</b>
      </div>
      <div class="r-block">
        <a class="start_test start">View</a>
      </div>
    </router-link>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},
  mixins: [],
  props: ["title", "router"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.tpos {
  display: block;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  height: 80px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: var(--themeColor);
}
.tpos:hover h3 {
  color: var(--themeColor);
}
.tpos:hover .start {
  color: #fff !important;
  opacity: 1;
  top: 26px;
}

.tpos:hover .testresult {
  opacity: 1;
  bottom: 20px;
}
.tpos:hover .l-block {
  opacity: 0;
}
.tpos:hover .first_start {
  opacity: 1;
  top: 48px;
}

.r-block a:first-child {
  top: 0px;
  background-color: var(--themeColor);
}
.l-block {
  width: 100%;
  display: block;
  float: left;
}
.l-block .itop {
  margin-top: 10px;
  height: 40px;
  vertical-align: bottom;
}
.l-block .ileft {
  margin-left: 40px;
  height: 80px;
  margin-left: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  padding: 10px 20px 10px 20px;
}

.l-block p {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
}
.ibottom {
  height: 70px;
  padding-left: 20px;
}
.itop h3,
.l-block p,
.ibottom label {
  display: inline-block;
}
.l-block .itop h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: normal;
  font-family: "Source Sans Pro", sans-serif;
}
.r-block {
  width: 100%;
  display: block;
  position: absolute;
  height: 120px;
  float: right;
  text-align: center;
}
.r-block a:first-child {
  top: 0px;
  background-color: var(--themeColor);
}

.r-block a {
  width: 186px;
  height: 28px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  border-radius: 50em;
  opacity: 0;
  color: #fff;
  text-align: center;
  line-height: 24px;
  transition: 0.5s 0s;
  border: 2px solid white;
}
a {
  user-select: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
@media screen and (max-width: 992px) {
  .l-block {
    width: 100%;
    text-align: center;
  }
  .l-block .ileft {
    margin-left: 0;
    font-weight: 500;
  }
  .r-block {
    display: none;
  }
}
@media screen and (max-width: 736px) {
  .tpos .start_test.start {
    color: #fff !important;
    opacity: 1;
    top: 26px;
    left: 0;
  }
  .r-block {
    height: auto;
  }
}
</style>
